import React from "react";

const Search = () => (
  <div className="px-5 py-8 flex flex-wrap  items-center bg-white">
    <div className="flex md:flex-no-wrap flex-wrap justify-center mx-auto ">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          window.location =
            "https://cse.google.com/cse?cx=partner-pub-5819082275382135:lulvf9yvrpq&q=" +
            encodeURI(e.target.q.value);
        }}
      >
        <input
          className="sm:w-64 w-40 bg-gray-100 rounded sm:mr-4 mr-2 border border-gray-400 focus:outline-none focus:border-indigo-500 text-base py-2 px-4"
          placeholder="Digite aqui"
          name="q"
          type="text"
        />
        <button className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded">
          Buscar
        </button>
      </form>
    </div>
  </div>
);

export default Search;
